import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal, Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { PrimaryButton } from '../Button';
import Datepicker from '../Datepicker';
import { datepickerFormatIncremented, datepickerFormat, mongoToTrx, NOT_STARTED, getMinEndDate } from '../utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '../CriticalPath/WarningCriticalMsg';

export const TaskUpdateRequestModal = ({ task, close, accept, canProposeFinish = false, isBuilder = false }) => {
  const startDate = datepickerFormatIncremented(mongoToTrx(task.get('startDate')), 1);
  const finishDate = datepickerFormatIncremented(mongoToTrx(task.get('expectedFinishDate')), 1);
  const isTaskNotStarted = task.get('status') === NOT_STARTED;
  const [isStartDateUpdateRequest, setUpdateRequestKind] = React.useState(canProposeFinish ? isTaskNotStarted : true);
  const updateRequestKind = isStartDateUpdateRequest ? 'finish' : 'start';
  const defaultDate = isStartDateUpdateRequest ? startDate : finishDate;
  const [date, setDate] = React.useState(defaultDate);
  const [isChecked, setIsChecked] = React.useState(false);
  const title = canProposeFinish ? 'Update Request' : 'Start Date Confirmation Update Request';

  const { handleSubmit, control, register } = useForm({ defaultDate });

  const toggleUpdateRequestKind = e => {
    e.preventDefault();
    const date = isStartDateUpdateRequest ? finishDate : startDate;
    setDate(date);
    setUpdateRequestKind(!isStartDateUpdateRequest);
  };

  return (
    <Modal show={true} onHide={close} id="task-start-request">
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <h6 className="font-weight-bold">Task: {task.get('name')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(form => accept({ form }))}>
        <Modal.Body className={cn('mt-n3', { 'pb-2': !canProposeFinish })}>
          {isStartDateUpdateRequest && (
            <FormDates
              control={control}
              date={date}
              name="startDate"
              label="Propose Start"
              task={task}
              isStartDate={isStartDateUpdateRequest}
            />
          )}
          {!isStartDateUpdateRequest && (
            <FormDates
              control={control}
              date={date}
              name="finishDate"
              label="Propose Finish"
              task={task}
              minDate={getMinEndDate(task.get('startDate'))}
              isStartDate={isStartDateUpdateRequest}
            />
          )}
          <Form.Group controlId="reasons" className="m-0 ">
            <CheckBox
              ref={register}
              type="checkbox"
              label="Due to Weather"
              name="reasons"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
          </Form.Group>
          {canProposeFinish && (
            <button
              className="btn btn-link align-self-start p-0 mt-2"
              onClick={toggleUpdateRequestKind}
            >{`Propose ${updateRequestKind} instead?`}</button>
          )}
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <PrimaryButton variant="secondary" className="mr-1" onClick={() => close()}>
            Cancel
          </PrimaryButton>
          <PrimaryButton type="submit" className="ml-3">
            {isBuilder ? 'Send for approval' : 'Send to Builder'}
          </PrimaryButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const FormDates = ({ control, date, name, label, minDate = null, task, isStartDate }) => {
  let taskDate, lastTaskDate;
  if (isStartDate) {
    taskDate = task.get('startDate') ? datepickerFormat(mongoToTrx(task.get('startDate'))) : '';
    lastTaskDate = task.get('lateStartDate') || undefined;
  } else {
    taskDate = task.get('expectedFinishDate') ? datepickerFormat(mongoToTrx(task.get('expectedFinishDate'))) : '';
    lastTaskDate = task.get('lateEndDate') || undefined;
  }
  const criticalDate = new Date(lastTaskDate);

  return (
    <Form.Group as={Col} md="6" controlId="formDate" className="d-flex flex-column px-0">
      <Form.Label>{label}</Form.Label>
      <Controller
        control={control}
        name={name}
        defaultValue={date}
        render={({ onChange, value, name }) => (
          <Datepicker
            name={name}
            selected={value}
            onChange={onChange}
            placeholderText=""
            minDate={minDate}
            dayClassName={date => criticalProposedDateClass(date, value, taskDate, criticalDate)}
            renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, value)}
            calendarClassName="react-datepicker__critical"
            filterDate={currentDate => {
              if (!value) return true;
              const blockedDate = new Date(date.getTime());
              blockedDate.setDate(blockedDate.getDate() - 1);
              return currentDate.getTime() !== blockedDate.getTime();
            }}
          >
            <WarningCriticalMsg showMsg={showWarningCriticalMsg(value, criticalDate)} />
          </Datepicker>
        )}
      />
    </Form.Group>
  );
};

const CheckBox = styled(Form.Check)`
  display: flex;
  align-items: center;
  .form-check-input {
    height: 18px;
    width: 18px;
  }
  .form-check-label {
    margin-left: 5px;
    margin-top: 4px;
    font-size: 16px;
  }
`;
