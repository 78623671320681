import React from 'react';
import cn from 'classnames';
import { List, Map } from 'immutable';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const unassignedOption = Map({ name: 'Unassigned', _id: 'unassigned', subAccountId: 'unassigned' });

export const FilterTypeahead = filterContext => ({ text, options, name, field, showUnnasigned = false }) => {
  const { filterState, filterController, isCollapsed } = filterContext;
  const values = filterState.get('values');
  const selectedValue = values.get(name) || List();
  const counter = selectedValue?.size || 0;
  const isSectionCollapsed = isCollapsed(`${name}-section`, false);
  const mapValue = value => List(value).map(v => v.get(field));
  const onChange = value => filterController.defer.updateFieldValue(name, value);
  const toggleCollapsed = () => {
    filterController.defer.setCollapsedSection(`${name}-section`, !isSectionCollapsed);
  };
  // TOOD: optimize this code, when there are too many options this could become slow.
  const [selected, availableOptions] = React.useMemo(() => {
    if (showUnnasigned) {
      options = options.unshift(unassignedOption);
    }

    return [
      options.reduce((selected, opt) => {
        const optionValue = opt.get(field);
        if (selectedValue.includes(optionValue)) {
          selected.push(opt);
        }
        return selected;
      }, []),
      options.toArray(),
    ];
  }, [selectedValue, options, showUnnasigned]);

  return (
    <>
      <div className="d-flex justify-content-between py-4 border-top">
        <span className="font-weight-bold">{text}</span>
        <span>
          {counter > 0 ? (
            <span className="badge rounded-pill bg-danger text-light">{counter > 99 ? '+99' : counter}</span>
          ) : (
            'All'
          )}
          <FontAwesomeIcon
            className="mx-3 cursor-pointer"
            icon={`chevron-${isSectionCollapsed ? 'down' : 'up'}`}
            onClick={toggleCollapsed}
          />
        </span>
      </div>
      <div className={cn({ invisible: isSectionCollapsed, 'h-0': isSectionCollapsed })}>
        <Typeahead
          className="mb-4 d-flex align-items-top"
          clearButton
          id={`${text.replace(' ', '-')}`}
          labelKey={option => option.get('name') ?? `${option.get('firstName')} ${option.get('lastName')}`}
          multiple
          onChange={value => onChange(mapValue(value))}
          options={availableOptions}
          placeholder={`Search ${text}`}
          paginationText="See more results..."
          selected={selected}
          flip
        >
          {counter === 0 && <FontAwesomeIcon icon="magnifying-glass" className="mx-1" />}
        </Typeahead>
      </div>
    </>
  );
};
