import styled from 'styled-components';
import { colors } from '../../theme';

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 44px;
  transition: right 600ms ease;
  mix-blend-mode: normal;
  z-index: 1000;
  display: flex;
  &.hidden {
    pointer-events: none;
  }

  .settings-container {
    background: ${colors.pearl};
    border-radius: 10px;
    padding: 1.75rem;
    width: 450px;
    border-top: solid 10px ${colors.blue300};
    position: absolute;
    top: 5px;
    right: 0;
    @media (max-width: 1670px) {
      ${props => `
        right:${props.floatToRight ? '-410px' : '0px'};
      `}
    }
    box-shadow: 0px 1px 2px rgb(51 51 51 / 20%);
    max-height: ${props => `${props.reachBottom ? 'calc(100vh - 350px)' : '680px'};`};
    overflow-y: scroll;
    margin-bottom: 33px;

    &.hidden {
      display: none;
    }

    .rbt ul.dropdown-menu {
      max-height: 175px !important;
    }
  }

  &.size-sm {
    position: absolute;
    right: 0;
    top: 44px;

    .settings-container {
      width: 450px;
    }

    .status-section {
      padding: 0.5rem 0 0 1.5rem;
      flex-wrap: wrap;
      div {
        width: 170px;
      }
    }
  }

  &.size-lg {
    position: absolute;
    top: 44px;
    right: 0;

    .settings-container {
      position: relative;
      overflow: scroll;
      width: 536px;
      @media (min-width: 1020px) {
        width: 700px;
      }
    }

    .status-section {
      justify-content: space-between;
      margin-right: 4rem;
      margin-bottom: 2rem;
    }

    .form-control.size-sm {
      width: 72px;
      padding-right: 0.5rem !important;
    }
  }
  .secondary-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;
